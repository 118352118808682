import styled from 'styled-components';
import { theme, Title } from '../globalStyles';
import introBackgroundImage from '../assets/backgroundSAP.jpg';
import endBackgroundImage from '../assets/endScreenWave.png';
import { Link } from 'react-router-dom';

export const IntroContainer = styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
  
  .titleTxtQueryCont{
    display:flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    margin-top: -4vh;
  }

  @media (max-height: 620px) {
    .svgAnimationCont{
      width: 90%; 
      margin-top: -5vh;
    }
    .svgAnimationContGlobal{
      width:90%;
    }
  }

  .logoRed {
    @media (max-height: 620px) {
      padding-top: 24px;
    }

    padding-top: 2rem;
    flex-shrink: 0;
    width: 124px;
    height: 47px;
  }
`;

export const ViewContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: no-repeat top center url(${introBackgroundImage});
  display: flex;
  padding-top: 20vh;
`

export const TitleTxtContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem 0 1.5rem;
  text-align: left;
`;

export const EndViewTitleTxtContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 17vh 3rem;
  text-align: left;
`;

export const TitleTxt = styled(Title)`
  margin: 0;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 60px;
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 40px;
  display: flex;
  align-items: left;
  justify-content: space-around;
  z-index: 10;
  margin: 0 0 1rem 1rem;
`

export const FooterLink = styled(Link)`
  color: ${theme.colors.white};
  margin-top: 15px;
  font-size:  0.75rem;
  padding: 0.5rem 0;
  transition: all 0.3s linear;
  &:hover {
    border-bottom: 1px solid white;
  }
`

export const EndViewContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background:rgba(37,53,93,0.3) url(${endBackgroundImage}) no-repeat fixed center center ;
  display: flex;
  /* padding-top: 20vh; */
`
