import styled from 'styled-components'

export const LegendContainer = styled.div`
  padding: 18.5px 32px;
  box-sizing: content-box;
  position: absolute;
  bottom: 10px;
  left: 18px;
  right: 18px;
  border-radius: 10px;
  font-size: 18px;
  line-height: 1.5em;
  background: ${props => props.theme.colors.PopUpGraphContainer.background};
  color: white;
  z-index: 10;
  backdrop-filter: blur(4px);
  align-items: center;
  opacity: 0;
`