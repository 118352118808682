import { Color } from 'three';

// ground plane for raycast intersection.
export const GroundPlane = ( props ) => {
    return (
        <mesh
            ref={props.groundRef}
            name={'groundPlane'}
            position={[0, 3, 0]}
            rotation={[-Math.PI * 0.5, 0.0, 0.0]}
            visible={true}
        >
            <planeBufferGeometry args={[60.0, 60.0]} />
            <meshBasicMaterial
                color={new Color(0xff00ff)}
                wireframe={true}
            />
        </mesh>
    )

}

