import * as THREE from 'three';
import GraphModel from '../../GraphModel';
import { GroundPlane } from '../../../GroundPlane';

const GraphAR = (props) => {
  return (
    <>
      {/*<mesh {...props} name={'graphARMesh'}>*/}
      {!props.isVisible && <GroundPlane groundRef={props.groundRef}/> }
      <mesh name={'graphARMesh'}>
        <GraphModel isVisible={props.isVisible} />
      </mesh>
    </>
  );
};

export default GraphAR;
