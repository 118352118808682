import React, { useRef, useEffect } from 'react';
import { useUIStore } from '../../services/uiService';
import { useXrStore } from '../../services/xrService';
import useSleep from '../../hooks/useSleep';
import {
  LottieContainer,
  FocusAreaContainer,
  FocusAreaIcon,
  FocusAreaText,
} from './styles';
import Lottie from 'lottie-react';
import focusAnimation from '../../lottie/Focus_area.json';
import { STATUS } from '../../services/graphService';

const FocusArea = ({ text, selectedSphere }) => {
  const { showImprovementCircularBar, popupAnimationDelay, setShowFocusArea } = useUIStore();
  const { screenTouchedFirstTimeAR } = useXrStore();
  const lottieRef = useRef(null);
  const sleep = useSleep();

  useEffect(() => {
    if(selectedSphere && selectedSphere?.status !== STATUS.neutral) {
      lottieRef?.current?.playSegments([0, 30], true);

      sleep(popupAnimationDelay).then(() => {
        lottieRef?.current?.playSegments([40, 100], false);
      });

      sleep(popupAnimationDelay*2).then(() => {
        setShowFocusArea(false);
      });
    }
  },[selectedSphere, setShowFocusArea])

  return (
    <>
      {screenTouchedFirstTimeAR && !showImprovementCircularBar && (
        <FocusAreaContainer id="FocusAreaContainer">
          <FocusAreaIcon>
            <LottieContainer>
              <Lottie
                lottieRef={lottieRef}
                autoplay={false}
                loop={false}
                animationData={focusAnimation}
              />
            </LottieContainer>
            <FocusAreaText>{text}</FocusAreaText>
          </FocusAreaIcon>
        </FocusAreaContainer>
      )}
    </>
  );
};
export default FocusArea;
