import { ReactElement, Suspense } from 'react';
import create from 'zustand';
import LinesInstance from '../../components/threeAR/GraphModel/LinesInstance';
import SphereInstance from '../../components/threeAR/GraphModel/SphereInstance';

const clickEvent = (e) => e.stopPropagation();

type ModelItemType = {
  name: string;
  uppercaseName: string;
  offset: Array<number>;
  introScale: number;
  component: (
    sphereRef,
    hotspotRef?,
    position?,
    id?,
    status?,
    hotspotWhite?,
    isHotspotVisible?,
    connectionids?,
    props?,
  ) => ReactElement;
};

type ModelType = {
  graphIsSet: boolean;
  sphereModel: ModelItemType;
  connectionModel: ModelItemType;
};

// get position of POV (point of view) reference
const getPovPos = (scale) => {

  let distanceCorrection = 0;

  if(scale !== 6){
    distanceCorrection = (6 - scale);
  }  

  const newPovPos = [
    0,
    0,
    (23 / scale ) - distanceCorrection
  ]

  // 0.17867787182331085, 3.0094099044799805, 3.85

  return newPovPos;
}

export function AnamorphicModel( props ) {

  return (
    <group name='anamorphicARGroup' scale={props.scale} position={ props.position } >
      <Suspense fallback={null}>
        <primitive object={props.glbText.scene} name='Text'>
          <mesh
            name={'cubeTest'}
            position={getPovPos(props.scale)}
            visible={true}
            ref={props.povRef}
            >
            <boxBufferGeometry args={[0.2, 0.2, 0.2]} />
            <meshNormalMaterial opacity={0.5} transparent />        
          </mesh>
        </primitive>
      </Suspense>
      {/* <primitive object={props.glbCube.scene} name='Cube'/> */}
    </group>
  )
}

export const useModelStore = create<ModelType>((set, get) => ({
  graphIsSet: false,
  sphereModel: {
    name: 'sphere3dElement',
    uppercaseName: 'Sphere3dElement',
    offset: [0, 0, 0],
    introScale: 0.6,
    component: (
      sphereRef,
      hotspotRef,
      position,
      id,
      status,
      hotspotWhite,
      isHotspotVisible,
      connectionids,
      props,
    ) => {
      return (
        <SphereInstance
          {...props}
          name="sphere"
          key={`sphere_${id}`}
          sphereId={id}
          status={status}
          sphereRef={sphereRef}
          hotspotsRef={hotspotRef}
          hotspotAsset={hotspotWhite}
          isHotspotVisible={isHotspotVisible}
          connectionids={connectionids}
          hotspotScale={0.16}
          sphereScale={0.05}
          position={position}
          rotation={[0, 0, 0]}
          onClick={(event) => clickEvent(event)}
        />
      );
    },
  },
  connectionModel: {
    name: 'line3dElement',
    uppercaseName: 'Line3dElement',
    offset: [0, 0, 0],
    introScale: 0.6,
    component: (ref, position, points, id, thickness, lineAmount,startSphere, endSphere, props) => {
      return (
        <LinesInstance
          {...props}
          name="line"
          key={`line_${id}`}
          startPoint={points[0]}
          controlPoint1={points[1]}
          controlPoint2={points[2]}
          endPoint={points[3]}
          position={position}
          lineThickness={(thickness * 60)}
          numberOfLines={lineAmount}
          startSphere={startSphere}
          endSphere={endSphere}
          healthState={true}
          movementIntensity={0.1}
          onClick={(event) => clickEvent(event)}
        />
      );
    },
  },
}));
