import styled, { css } from 'styled-components';
import ButtonArrow from '../ButtonArrow';

export const StyledButtonArrow = styled(ButtonArrow)`
 > svg path {
    fill: ${(props) => props.isSolved ? props.theme.colors.white : props.theme.colors.blue}
 }
  ${(props) =>
    props.isSolved &&
    css`
      pointer-events: none;
      color: white;
      background-color: ${props.theme.colors.lightBlue}

     
`}
`;
