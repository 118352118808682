import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyle from './globalStyles';
// import reportWebVitals from './reportWebVitals';

declare const window: any;

/*
declare const window: Window &
   typeof globalThis & {
      XR8:any;
   }
}
*/
/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyle />
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )

  // grabbing reference of canvas
  // let canvasEl = document.getElementById('root')
  //attaching canvas reference to 8th Wall
  // window.XR8.run({ canvas: canvasEl})
  // console.log(window)
}

// window.XR8 ? render() : window.addEventListener('xrloaded', render)
window.XRExtras ? render() : window.addEventListener('xrextrasloaded', render)


// console.log('is here')
// render();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
