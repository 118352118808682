import { SphereStatusType } from '../../services/graphService'
const sequentialDataJSON = require('../../data/sequentialStates.json');
const lineAnimationDataJSON = require('../../data/lineAnimations.json');

export type SequentialSphereStatusType = {
  id: number,
  newStatus: SphereStatusType
}

export type SequentialLineAnimationType = {
  id: string,
  newThickness: number
}

export type SequentialSphereType = {
  sphereStatus: SequentialSphereStatusType[],
  lineAnimations?: SequentialLineAnimationType[]
}

class SequentialData {
  pdcSteps: SequentialSphereType[]

  private static _instance:SequentialData = new SequentialData();

  constructor() {
    if(SequentialData._instance){
      throw new Error("Error: Instantiation failed: Use SequentialData.getInstance() instead of new.");
    }

    const sphereStatusArray = sequentialDataJSON.pdcSteps;
    const lineAnimationsArray = lineAnimationDataJSON.pdcSteps;

    this.pdcSteps = sphereStatusArray.map((sphereStatus,i) => {
      return {...sphereStatus, ...lineAnimationsArray[i]}
    });

    SequentialData._instance = this;
  }

   /**
   *
   * Returns the instance on a GraphData object
   * @returns GraphData
   */
    public static getInstance():SequentialData{
      return SequentialData._instance;
    }
}

export const SequentialModel = SequentialData.getInstance()
