import styled from 'styled-components'
import { BoldText, BlackText } from '../../globalStyles'


export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.6rem 0 0 0.6rem;
  /* width: 35vw; */
  height: fit-content;
  letter-spacing: 0.52px;

  >*{
    margin: 0;
    padding: 0;
  }
`

export const HeadLine = styled(BoldText)`
  font-size: 0.85rem;
  text-align: left;
`

export const CounterContainer = styled.div`
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Counter = styled(BlackText)`
  margin-top: 0.3rem;
`