import React from 'react';
import PopUpContainer from '../PopUpContainer';
import { Container, Headline, LottieContainer } from './styles';
import Lottie from 'lottie-react';
import mobileAnimation from '../../lottie/Mobile_search.json';
import mobileAnimation2 from '../../lottie/Mobile_search_2.json';

const InitialPopUp = (props) => {

  const textAnamorphicTutorial = "Move around to change perspective";
  const textGraphTutorial = "Check Your Activities";

  const isAnamorphic = props.game === 'anamorphic_ar'

  return (
    <PopUpContainer>
      <Container>
        <LottieContainer>
          <Lottie
            autoplay
            loop={true}
            animationData={isAnamorphic ? mobileAnimation2 : mobileAnimation}
          />
        </LottieContainer>
      </Container>
      <Headline>{isAnamorphic ? textAnamorphicTutorial : textGraphTutorial }</Headline>
    </PopUpContainer>
  );
};

export default InitialPopUp;
