const MULTIPLIER = 1;

export const formatDataToPosition = point => [
  point.tx * MULTIPLIER,
  point.ty * MULTIPLIER,
  point.tz * MULTIPLIER
];

/**
 * Given a point, gives destructured array of coordinates [x, y, z] for graph placement
 * @param Point
 * @returns Array[x,y,z]
 */
export const getObjectPosition = ({ x, y, z }) => {
  return [x, y, z];
}

export * from "./graphData";
export * from "./contentData";
export * from "./sequentialStatesData";