import { useRef, useCallback, useMemo } from 'react'
import gsap from 'gsap'
import styled, { css } from 'styled-components'
import { HAS_TOUCH_POINTS } from '../../../constants'

const MagneticButton = ({
    children,
    className,
    speed = 1,
    tolerance = 1.2,
    scale = 1.2,
    debug = false,
    borderRadius = 0,
    onMouseEnter,
    onMouseLeave,
    alignLeft,
    alignRight,
    ...props
}) => {
    const $root = useRef()
    const $item = useRef()
    const $hover = useRef()
    const rootBound = useRef()
    const itemBound = useRef()
    const diffBound = useRef({ x: 0, y: 0 })

    const handleMouseEnter = useCallback((e) => {
        gsap.killTweensOf($item.current)
        gsap.set($hover.current, {
            scale,
            borderRadius,
            background: debug ? 'rgba(0, 125, 255, .4)' : 'transparent',
        })

        rootBound.current = $root.current.getBoundingClientRect()
        itemBound.current = $item.current.getBoundingClientRect()
        diffBound.current.x = (rootBound.current.width * scale - rootBound.current.width) / 2
        diffBound.current.y = (rootBound.current.height * scale - rootBound.current.height) / 2
    }, [scale, borderRadius, debug])

    const handleMouseLeave = useCallback((e) => {
        gsap.killTweensOf($item.current)
        gsap.to($item.current, {
            x: 0,
            y: 0,
            ease: 'elastic.out(1.1, .4)',
            duration: 1.2,
        })
        gsap.set($hover.current, {
            scale: 1,
        })
    }, [])

    const handleMouseMove = useCallback((e) => {
        if (!rootBound.current || !itemBound.current) return

        const x = e.touches ? e.touches[0].clientX : e.clientX
        const y = e.touches ? e.touches[0].clientY : e.clientY

        const maxX = ((rootBound.current.width - itemBound.current.width) / 2) * tolerance
        const maxY = ((rootBound.current.height - itemBound.current.height) / 2) * tolerance

        const newX = gsap.utils.mapRange(
            0,
            rootBound.current.width * scale,
            -maxX,
            maxX,
            x - rootBound.current.x + diffBound.current.x,
        )

        const newY = gsap.utils.mapRange(
            0,
            rootBound.current.height * scale,
            -maxY,
            maxY,
            y - rootBound.current.y + diffBound.current.y,
        )

        gsap.killTweensOf($item.current)
        gsap.to($item.current, {
            x: newX,
            y: newY,
            ease: 'power3.out',
            duration: speed,
        })
    }, [scale, speed, tolerance])

    const mouseTouchEvents = useMemo(() => {
        if (HAS_TOUCH_POINTS) {
            return {
                onTouchMove: handleMouseMove,
                onTouchStart: handleMouseEnter,
                onTouchEnd: handleMouseLeave,
            }
        }
        return {
            onMouseEnter: handleMouseEnter,
            onMouseMove: handleMouseMove,
            onMouseLeave: handleMouseLeave,
        }
    }, [handleMouseEnter, handleMouseLeave, handleMouseMove])

    return (
        <div
            ref={$root}
            className={`magnetic-button ${className}`}
            {...mouseTouchEvents}
            {...props}
        >
            <span
                ref={$item}
                className="magnetic-button--item cursor-pointer"
            >
                {children}
            </span>
            <span
                ref={$hover}
                className="magnetic-button--hover"
            />
        </div>
    )
}

const StyledMagneticButton = styled(MagneticButton)`
  border: none;
  padding: ${({ padding }) => padding || '1rem'};
  ${props => props.alignLeft && css`margin-left: -${props.padding || '1rem'};`};
  ${props => props.alignRight && css`margin-right: -${props.padding || '1rem'};`};
  background: transparent;
  position: relative;
  z-index: 1;
  touch-action: none;
  
  span {
    display: inline-block;
  }
  
  &--hover {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

export default StyledMagneticButton
