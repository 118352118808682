/**
 * 
 * Get the data from a list of hours
 * @param daysInHours Number
 * @returns 
 */
export const getDateFromHours = (daysInHours:number): Array<number> => {
  const days = Math.floor(daysInHours / 24) || 0;
  if (!(daysInHours % 24)) return [days, 0]
  return [days, (daysInHours - days*24)]
}