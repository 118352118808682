import React, { useRef, useMemo, useEffect, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { Vector3, DoubleSide, Color, SphereGeometry } from 'three'
import { STATUS } from '../../../services/graphService';
import {isEmpty} from 'lodash';

// eslint-disable-next-line import/no-webpack-loader-syntax
import sphereVertex from '!!raw-loader!../../../shaders/sphereVertex.glsl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import sphereFragment from '!!raw-loader!../../../shaders/sphereFragment.glsl';
import { useXrStore } from '../../../services/xrService';
import irradiance from './irradiance';

const handleColor = (status) => {
  switch (status) {
    case STATUS.wounded:
      return '#E43A4A';
    default:
      return '#334CD2';
  }
};

const handleSecondaryColor = (status) => {
  switch (status) {
    case STATUS.wounded:
      return '#C0387D';
    default:
      return '#74EBDE';
  }
};


const handleThirdColor = (status) => {
  switch (status) {
    case STATUS.wounded:
      return '#C0387D';
    default:
      return '#2F3E95';
  }
};

// Given sphere status, returns which hotspots are rendered (neutral spheres => no hotspot)
const handleHotspotVisibility = (isVisible, status) => {
  // todo: add status healthy solved and wound solved
  if (!isVisible || status === STATUS.neutral || status === STATUS.healthySolved || status === STATUS.woundedSolved) return false;
  return true;
};

const SphereInstance = (props) => {

  const {
    name,
    sphereId,
    hotspotScale,
    sphereScale,
    rotation,
    onClick,
    sphereRef,
    hotspotRef,
    position,
    status,
    hotspotAsset,
    isHotspotVisible,
  } = props;

  const [geometryCreated, setGeometryCreated] = useState(false);

  //ref to each individual sphere and hospot
  const sphereInstantRef = useRef(sphereRef);
  const hotspotInstantRef = useRef(hotspotRef);

  const sphereShaderProps = useMemo(
    () => ({
      uniforms: {
        u_PrimaryColor: {
          value: new Color("red")
        },
        u_SecondaryColor: {
          value: new Color("purple")
        },
        u_ThirdColor: {
          value: new Color("blue")
        },
        u_Size: {
          value: 1
        },
        u_vIrradiance: {
          value: irradiance,
        },
      },
      vertexShader: sphereVertex,
       fragmentShader: sphereFragment,
    }),
    []
  );

  const createGeometry = () => {
    if(geometryCreated)
      return;

    if(!isEmpty(sphereInstantRef) && sphereInstantRef.current ) {
      sphereInstantRef.current.geometry = new SphereGeometry( 1,25,25 );
    }
    setGeometryCreated(true);
  };

  // Object faces user/camera
  const faceCamera = (camPos: Vector3) => {
    hotspotInstantRef.current.lookAt(camPos.x, camPos.y, camPos.z);
  };

  // Forces hotspot planes to always look towards user/camera, every X ms
  useEffect(() => {
    const interval = setInterval(() => {
      if (hotspotInstantRef.current && isHotspotVisible) {
        const { scene3 } = useXrStore.getState();
        const camPos = scene3.scene.camera.position;
        faceCamera(camPos);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [isHotspotVisible])

  useEffect(() => {
    createGeometry();
  }, []);

  const lerp = (start, end, amt) => {
    return (1-amt)*start+amt*end;
  };

  const lerpColor = (start, end, t) => {
    return new Color(
      lerp(start.r, end.r, t),
      lerp(start.g, end.g, t),
      lerp(start.b, end.b, t),
      lerp(start.a, end.a, t),
    );
  };

useFrame((state, delta) => {
  const topcolor = new Color(handleColor(status));  
  const bottomcolor = new Color(handleSecondaryColor(status)); 
  const thirdColor = new Color(handleThirdColor(status)); 
  sphereInstantRef.current.material.uniforms.u_PrimaryColor.value.set(lerpColor(sphereInstantRef.current.material.uniforms.u_PrimaryColor.value, topcolor, delta * 4));
  sphereInstantRef.current.material.uniforms.u_SecondaryColor.value.set(lerpColor(sphereInstantRef.current.material.uniforms.u_SecondaryColor.value, bottomcolor, delta * 4));
  sphereInstantRef.current.material.uniforms.u_ThirdColor.value.set(lerpColor(sphereInstantRef.current.material.uniforms.u_ThirdColor.value, thirdColor, delta * 4));

});

  return (
    <group key={sphereId}>
      <mesh
        {...props}
        name="sphere"
        ref={sphereInstantRef}
        key={`sphere_${sphereId}`}
        sphereId={sphereId}
        status={status}
        scale={sphereScale}
        position={position}
        rotation={[0, 0, 0]}
        onClick={onClick}
      >
        <shaderMaterial attach="material" {...sphereShaderProps}
        transparent={true}
        />
      </mesh>


      {
        handleHotspotVisibility(isHotspotVisible, status) && (
        <mesh
          {...props}
          name="hotspot"
          ref={hotspotInstantRef}
          scale={hotspotScale}
          position={position}
          key={`hotspot_${sphereId}`}
        >
          <planeBufferGeometry args={[1, 1, 1]} />
          <meshBasicMaterial
            attach="material"
            map={hotspotAsset}
            depthTest={false}
            depthWrite={false}
            transparent={true}
          />
        </mesh>
      )}
    </group>
  )
}

export default SphereInstance;
