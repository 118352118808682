import React from 'react';
import { StyledButtonArrow } from './styles';

export const HealthyButton = ({
  isMagnetic,
  isWhite,
  width,
  text,
  isSolved,
  onClick,
}) => {
  return (
    <StyledButtonArrow
      onClick={onClick}
      isWhite={isWhite}
      width={width}
      isMagnetic={isMagnetic}
      isSolved={isSolved}
      text={text}
    />
  );
};
