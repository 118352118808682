import styled from 'styled-components'
import { Link } from "react-router-dom";

export const UIAnamorphicContainer = styled.div`
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
`;

export const LinkExitExperience = styled(Link)`
  bottom: 15px;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  text-decoration: underline !important;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
`

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 60px;
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`