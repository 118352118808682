import { withRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import XRCanvas from '../components/threeAR/XR8Canvas';

const ARExperience = withRouter(({ match }) => (
  <React.Fragment>
    <Suspense fallback={null}>
      <XRCanvas route={match} />
    </Suspense>
  </React.Fragment>
));

export default ARExperience;
