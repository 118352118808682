import React from 'react';

export function BackBtnIcon(props) {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <circle cx={34} cy={30} r={24} fill="#F4F3EF" />
      </g>
      <path
        d="M37.75 39.375L28.375 30l9.375-9.375"
        stroke="#89764B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="prefix__filter0_d"
          viewBox="0 0 68 68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export function ClockIcon(props) {
  return (
    <svg viewBox="0 0 49.4 49" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={25.4} cy={25.4} fill="#03376a" opacity={0.5} r={21.1} />
      <path
        d="M26.1 12.8c-6.7 0-12.2 5.5-12.2 12.2s5.5 12.2 12.2 12.2S38.3 31.7 38.3 25s-5.4-12.2-12.2-12.2zm0 1.9c5.7 0 10.3 4.6 10.3 10.3s-4.6 10.3-10.3 10.3S15.8 30.7 15.8 25s4.6-10.3 10.3-10.3zm0 2.8c-.5 0-.9.4-.9.9V25c0 .3.2.6.5.8 1.6.9 3.3 1.9 4.9 2.8.4.3 1 .1 1.3-.3s.1-1-.3-1.3l-4.4-2.6v-6c-.1-.4-.6-.9-1.1-.9z"
        fill="#fff"
      />
    </svg>
  );
}

export function HotspotRed(props) {
  return (
    <svg
      width={79}
      height={78}
      viewBox="0 0 79 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.2} filter="url(#filter0_d_133_4707)">
        <ellipse cx={38.5} cy={39} rx={26.5} ry={26} fill="#FE4242" />
      </g>
      <circle cx={38.5} cy={39.5} r={19.5} stroke="white" strokeWidth={2} />
      <g filter="url(#filter1_d_133_4707)">
        <ellipse
          cx={38.5003}
          cy={39.0997}
          rx={7.74444}
          ry={7.68665}
          fill="#FE4242"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_133_4707"
          x={0}
          y={0}
          width={79}
          height={78}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={1}
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_133_4707"
          />
          <feOffset dx={1} />
          <feGaussianBlur stdDeviation={6} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_133_4707"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_133_4707"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_133_4707"
          x={18.7559}
          y={18.4131}
          width={41.4883}
          height={41.373}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={1}
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_133_4707"
          />
          <feOffset dx={1} />
          <feGaussianBlur stdDeviation={6} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_133_4707"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_133_4707"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export const IconArrow = (props) => (
  <svg
    width={10}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.213 7.886a.847.847 0 0 0-.228-.463l-6.72-7A.84.84 0 1 0 1.05 1.578L7.218 8l-6.169 6.423a.839.839 0 1 0 1.216 1.155l6.72-7a.84.84 0 0 0 .227-.691Z"
      fill="#000"
    />
  </svg>
);

export const SAPLogoSVG = (props) => (
  <svg
    width={90}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 44h45.254l44.29-44H0v44Z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.272 8.949h-8.788V29.51L36.808 8.942h-7.611l-6.553 17.196c-.701-4.335-5.247-5.833-8.84-6.956-2.369-.747-4.882-1.849-4.85-3.067.02-.997 1.35-1.917 3.98-1.785 1.768.091 3.326.232 6.43 1.704l3.053-5.23c-2.843-1.417-6.752-2.313-9.962-2.318h-.02c-3.74 0-6.857 1.193-8.788 3.16a7.233 7.233 0 0 0-2.102 5.044c-.05 2.652.94 4.537 3.017 6.029 1.754 1.265 3.996 2.085 5.975 2.688 2.44.744 4.431 1.391 4.407 2.77-.014.508-.22.992-.578 1.359-.608.617-1.543.852-2.833.873-2.488.051-4.33-.332-7.276-2.043l-2.712 5.307a20.552 20.552 0 0 0 9.957 2.603h.457c3.083-.054 5.572-.918 7.56-2.495.114-.087.216-.179.322-.272l-.892 2.31h7.972l1.34-4.008c1.514.494 3.1.74 4.693.73a15.01 15.01 0 0 0 4.58-.692l1.3 3.97h13.018v-8.307h2.838c6.864 0 10.923-3.44 10.923-9.203 0-6.414-3.944-9.36-12.34-9.36ZM32.96 26.557a8.11 8.11 0 0 1-2.814-.486l2.786-8.647h.047l2.739 8.673a8.458 8.458 0 0 1-2.758.46Zm20.825-4.969H51.85V14.61h1.935c2.583 0 4.641.852 4.641 3.439 0 2.684-2.058 3.53-4.64 3.53"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={0}
        y2={44}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B8F1" />
        <stop offset={0.02} stopColor="#01B6F0" />
        <stop offset={0.31} stopColor="#0D90D9" />
        <stop offset={0.58} stopColor="#1775C8" />
        <stop offset={0.82} stopColor="#1C65BF" />
        <stop offset={1} stopColor="#1E5FBB" />
      </linearGradient>
    </defs>
  </svg>
);

export const MobileMoveSVG = (props) => (
  <svg
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 111 50"
    style={{
      enableBackground: 'new 0 0 111 50',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M66.7 0H45.3C42.9 0 41 1.9 41 4.3v41.5c0 2.3 1.9 4.2 4.3 4.2h21.4c2.4 0 4.3-1.9 4.3-4.2V4.3c.1-2.4-1.9-4.3-4.3-4.3zM43.3 8.5h25.4V40H43.3V8.5zm2-6.2h21.4c1.1 0 2 .9 2 1.9v1.9H43.3V4.3c0-1.1.9-2 2-2zm21.4 45.4H45.3c-1.1 0-2-.9-2-1.9v-3.5h25.4v3.5c0 1-.9 1.9-2 1.9zm-9.4-3.9h-2.5c-.7 0-1.2.5-1.2 1.2 0 .6.5 1.2 1.2 1.2h2.5c.7 0 1.2-.5 1.2-1.2s-.6-1.2-1.2-1.2zM111 25.8c0-.3-.1-.6-.3-.9l-6.6-7c-.4-.4-1.3-.6-1.8-.1s-.5 1.3 0 1.8l4.7 4.9H84.1c-.7 0-1.2.6-1.2 1.2 0 .7.6 1.2 1.2 1.2h22.8l-4.7 4.9c-.4.4-.5 1.3 0 1.8s1.4.3 1.8-.1l6.6-7c.3 0 .4-.3.4-.7zM.2 25.8c0 .3.1.6.3.9l6.6 7c.4.4 1.3.6 1.8.1s.5-1.3 0-1.8l-4.6-4.9h22.8c.7 0 1.2-.6 1.2-1.2 0-.7-.6-1.2-1.2-1.2H4.3l4.7-5c.4-.4.5-1.3 0-1.8s-1.4-.3-1.8.1L.6 25c-.3.2-.4.5-.4.8z" />
  </svg>
);


export const IconCheck = (props) => (
  <svg
    width={15}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.234.005a.694.694 0 0 0-.405.197c-.267.255-2.334 2.287-4.435 4.351-1.902 1.87-3.515 3.457-4.002 3.93L1.1 5.354a.703.703 0 0 0-.962.12.634.634 0 0 0 .139.919l4.768 3.475c.272.197.654.17.894-.06.267-.256 2.334-2.282 4.435-4.345 2.1-2.064 4.233-4.166 4.42-4.345a.632.632 0 0 0 .139-.749.69.69 0 0 0-.7-.364Z"
      fill="#fff"
    />
  </svg>
);

export const IconClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    style={{
      enableBackground: "new 0 0 26 26",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M13.1.1C20.2.1 26 5.9 26 13.1 26 20.2 20.2 26 13 26 5.9 26 .1 20.2.1 13 .1 5.9 5.9.1 13.1.1z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m14.8 13.4 3.4-3.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-3.4 3.4-3.3-3.3c-.1-.1-.4-.3-.6-.3-.3 0-.5.1-.6.3-.4.4-.4 1 0 1.4l3.4 3.4-3.4 3.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l3.4-3.4 3.4 3.4c.1.1.3.2.5.2.3 0 .6-.1.7-.3.4-.4.4-.9 0-1.3l-3.5-3.5z"
      style={{
        fill: "rgba(37,53,93,0.7)",
      }}
    />
  </svg>
);


