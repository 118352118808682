import { UIAnamorphicContainer, LinkExitExperience, ButtonContainer } from './styles';
import { useXrStore } from '../../services/xrService';
import ButtonArrow from '../buttons/ButtonArrow';
import { useHistory } from 'react-router-dom';
import ZoomButton from '../buttons/ZoomButton';
import InitialPopUp from '../InitialPopUp';
import { useGameStore } from '../../services/gameService';
import { useUIStore, GRAPH_SCREENS} from '../../services/uiService';


const UIAnamorphicModel: React.FC = () => {
    const history = useHistory();
    const { setScreenTouchedFirstTimeAR, screenTouchedFirstTimeAR, setScaleAnamorphicParam } = useXrStore();
    const { currentGame, setCurrentGame,isAnamorphicSolved } = useGameStore()
    const { showInitialPopUp, setScreenGraphGame } = useUIStore()

    const toNextAR = () => {
        setCurrentGame('graph_ar');
        setScreenTouchedFirstTimeAR(false);
        setScreenGraphGame(GRAPH_SCREENS.pre);
        // and redirect to new AR scene
        history.push('/graph_ar');
    }

    return (
    <UIAnamorphicContainer>
    {!screenTouchedFirstTimeAR &&
        <>
            <ButtonContainer>
                <ButtonArrow
                    isMagnetic={false}
                    width="180"
                    isWhite
                    text="Tap to place"
                    onClick={() => setScreenTouchedFirstTimeAR(true) }
                    />
            </ButtonContainer>
            <ZoomButton zoomIn={() => setScaleAnamorphicParam(true)} zoomOut={() => setScaleAnamorphicParam(false)}/>
        </>
    }
    { screenTouchedFirstTimeAR && (
        <>
            { isAnamorphicSolved &&
            <ButtonContainer>
                <ButtonArrow
                isMagnetic={false}
                width="280"
                isWhite
                text="Next AR Piece"
                onClick={() => toNextAR()}
                /> 
            </ButtonContainer>}
            { showInitialPopUp && !isAnamorphicSolved && <InitialPopUp game={currentGame} />}
            { !showInitialPopUp &&
            <LinkExitExperience to="/takeaway">
                Transform your processes
            </LinkExitExperience>}
        </>
        )
    }
    </UIAnamorphicContainer>
    );
};

export default UIAnamorphicModel;
