import React from 'react';
import styled from 'styled-components';
import withMagneticButton from './MagneticButton/withMagneticButton';
import { IconArrow } from '../../utils/svg';
import { IconCheck } from '../../utils/svg';
import { styledBtn } from './styles';

type ButtonType = {
  width: string,
  isWhite: boolean,
  isLefty?: boolean,
  alignLeft?: boolean,
  alignRight?: boolean,
  isFullWidth?: boolean,
  isMagnetic?: boolean,
  isSolved?: boolean,
  text: string,
  onClick: () => void,
};

const ButtonMagnetic = styled(withMagneticButton(styledBtn))``;
const ButtonNormal = styledBtn;

const ButtonArrow: React.FC<ButtonType> = ({
  isWhite = false,
  isLefty = false,
  width = 230,
  alignLeft = false,
  alignRight = false,
  isFullWidth = false,
  isMagnetic = true,
  isSolved = false,
  text,
  onClick,
  children,
  ...rest
}) => {
  const content = <span>{text || children}</span>;

  const btnMagnetic = (
    <ButtonMagnetic
      onClick={onClick}
      isWhite={isWhite}
      isLefty={isLefty}
      width={width}
      alignLeft={alignLeft}
      alignRight={alignRight}
      isFullWidth={isFullWidth}
      isMagnetic={isMagnetic}
      isSolved={isSolved}
      {...rest}
    >
      {!isLefty && content}
        <IconArrow />
      {isLefty && content}
    </ButtonMagnetic>
  );

  const btnNormal = (
    <ButtonNormal
      onClick={onClick}
      isWhite={isWhite}
      isLefty={isLefty}
      width={width}
      alignLeft={alignLeft}
      alignRight={alignRight}
      isFullWidth={isFullWidth}
      isMagnetic={isMagnetic}
      isSolved={isSolved}
      {...rest}
    >
      {!isLefty && content}
      {isSolved ? <IconCheck/>  : <IconArrow />}
      {isLefty && content}
    </ButtonNormal>
  );

  return isMagnetic ? btnMagnetic : btnNormal;
};

export default React.memo(ButtonArrow);
