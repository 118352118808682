import styled, { css } from 'styled-components'

export const styledBtn = styled.button`
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: space-between;
  background: ${(props) =>
    props.isWhite
      ? props.theme.colors.button.primary.background
      : props.theme.colors.button.secondary.background};
  border-radius: 50px;
  color: ${(props) =>
    props.isWhite
      ? props.theme.colors.button.primary.color
      : props.theme.colors.button.secondary.color};
  border: none;
  ${(props) =>
    props.outline &&
    css`
      background: transparent;
      border: 2px solid ${props.theme.colors.button.primary.background};
      color: ${props.theme.colors.button.secondary.color};
      @media only screen and (max-width: 767px) {
        border-width: 1px;
      }
    `}
  font-family:'72 Bold';
  font-size: 14px;
  line-height: 14px;
  width: ${(props) => (props.isFullWidth ? '100%' : `${props.width}px`)};
  height: 45px;
  padding: 0 25px;
  letter-spacing: 0.525px;
  transition: all 0.1s ease-in;
  height: 45px;
  :link {
    color: inherit;
  }

  :active,
  :link {
    color: ${(props) =>
      props.isWhite
        ? props.theme.colors.button.primary.color
        : props.theme.colors.button.secondary.color};
    transform: scale(0.9, 0.9) translate3d(0, 3px, 0);
  }

  > svg {
    height: 16px;
    transform: ${(props) => (props.isLefty ? 'rotate(-180deg)' : null)};
  }

  > svg path {
    fill: ${(props) =>
      props.isWhite
        ? props.theme.colors.button.primary.color
        : props.theme.colors.button.secondary.color};
  }

  :hover {
    background: ${(props) =>
      props.isWhite
        ? props.theme.colors.button.primary.backgroundHover
        : props.theme.colors.button.secondary.backgroundHover};
    color: ${(props) =>
      props.isWhite
        ? props.theme.colors.button.primary.colorHover
        : props.theme.colors.button.secondary.colorHover};

    > svg path {
      fill: ${(props) =>
        props.isWhite
          ? props.theme.colors.button.primary.colorHover
          : props.theme.colors.button.secondary.colorHover};
    }
  }
`;