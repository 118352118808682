import React, { useCallback, useEffect, useRef } from 'react';
import { useXrStore } from '../../../services/xrService';
import { useGameStore } from '../../../services/gameService';
import AnamorphicAR from './ARPieces/AnamorphicAR';
import GraphAR from './ARPieces/GraphAR';
import { Vector3 } from 'three';

export default function ARScene(props) {
  const {
    isGameURLSet,
    setIsGameURLSet,
    setCurrentGame,
    gameComponent,
    setGameComponent,
    setIsAnamorphicSolved
  } = useGameStore();
  const {
    scene3,
    isSceneInitialised,
    screenTouchedFirstTimeAR,
    setGroundRef,
    setPovRef,
  } = useXrStore();

  // generateAngles(); //to be decided if this will be used...

  const arSceneRef = useRef();
  const groundRef = useRef();
  const povRef = useRef();

  const almostEqual = (camPos, povPos, margin) => {
    let _camPos = new Vector3(camPos.x, camPos.y, 0)
    let _povPos = new Vector3(povPos.x, povPos.y, 0)
    
    const dist = _camPos.distanceTo(_povPos)
    return  dist < margin;
  }

  const startDetectionLogic = () => {
    const interval = setInterval(() => {
      if(!useXrStore.getState().screenTouchedFirstTimeAR) return;
      // @ts-ignore
      almostEqual(scene3.scene.camera.position, povRef.current.position, 3.5) && setIsAnamorphicSolved()
    }, 500);
    return () => clearInterval(interval);
  }

  useEffect(() => {
    // scene3.scene.scene = the scene we're interested in changing
    // if scene exists, add Ref <group> to scene
    if (isSceneInitialised && arSceneRef.current) {
      scene3.scene.scene.add(arSceneRef.current);
      povRef.current && startDetectionLogic();
    }
  }, [scene3.scene, isSceneInitialised]);

  // which AR is in the url? Set the correct AR game to later render
  const gameSwitch = useCallback(
    (game) => {
      switch (game) {
        case 'anamorphic_ar':
          setGameComponent(
            <AnamorphicAR 
              isVisible={screenTouchedFirstTimeAR}
              groundRef={groundRef}
              povRef={povRef}
            />
          );
          break;
        case 'graph_ar':
          setGameComponent(
            <GraphAR
            isVisible={screenTouchedFirstTimeAR}
            groundRef={groundRef}
            />,
          );
          break;
        default:
          console.log('Potential error: wrong URL detected.')
          setGameComponent(
            <AnamorphicAR 
              isVisible={screenTouchedFirstTimeAR}
              groundRef={groundRef}
              povRef={povRef}
            />
          );
          break;
      }
    },
    [
      screenTouchedFirstTimeAR,
      setGameComponent,
    ],
  );

  // Detects game level: Checks URL and sets value on game service.
  if (props.route && !isGameURLSet) {
    setCurrentGame(props.route);
    setIsGameURLSet();
    gameSwitch(useGameStore.getState().currentGame);
  }

  // When screenTouchedFirstTimeAR changes, the visibility off our meshes will change.
  // Here we're updating this and rerendering our game component.
  useEffect(() => {
    gameSwitch(useGameStore.getState().currentGame);
  }, [ screenTouchedFirstTimeAR, gameSwitch]);

  useEffect(() => {
    setGroundRef(groundRef);
  }, [setGroundRef, groundRef]);
  
  useEffect(() => {
    setPovRef(povRef);
  }, [povRef, setPovRef]);

  return (
    <group ref={arSceneRef} name={'mainSceneGroup'}>
      {/* Main scene */}
      <directionalLight
        intensity={0.8}
        castShadow={true}
        name={'mainDirLight'}
      />
      <ambientLight intensity={0.5} name={'mainAmbLight'} />
      {gameComponent}
    </group>
  );
}
