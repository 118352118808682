import { useGLTF } from '@react-three/drei/'
import { useEffect } from 'react';
import { useGameStore } from '../../../../services/gameService';
import { AnamorphicModel } from '../../../../services/modelService';
import { useXrStore } from '../../../../services/xrService';
import { GroundPlane } from '../../../GroundPlane';
import { AnimationMixer } from 'three'
import { useFrame } from '@react-three/fiber';

const AnamorphicAR = (props) => {

  const { modelPositionAR, scaleAnamorphicParam } = useXrStore();
  const { isAnamorphicSolved } = useGameStore();

  const glbText = useGLTF("/3d/Text.glb");
  const {scene, animations } = useGLTF("/3d/Text.glb");
  const glbCube = useGLTF("/3d/Cube.glb");

  let mixer = new AnimationMixer(scene);

  const playAnim = () => {
    console.log(animations)
    animations.forEach((clip) => {
      const action = mixer.clipAction(clip);
      action.clampWhenFinished = false // this will pause the animation on last frame. Model won't disappear.
      action.enable = true 
      console.log(action);
      
      action.play();
    });

  }
  
  useFrame((state, delta) => {
    mixer.update(delta);
  });
  
  useEffect(() => {
    !isAnamorphicSolved ? console.log('not solved') : console.log('solved !!');
    isAnamorphicSolved && playAnim();
  }, [ isAnamorphicSolved ])

  return (
    <>
      {!props.isVisible && <GroundPlane groundRef={props.groundRef}/> }
      <AnamorphicModel position={modelPositionAR} scale={scaleAnamorphicParam} glbCube={glbCube} glbText={glbText} povRef={props.povRef} />
    </>
  );
};

export default AnamorphicAR;