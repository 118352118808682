import React from 'react';
import { Container, HeadLine, CounterContainer, Counter } from './styles';


interface CounterWoundPointsEl {
  solvedSpheres: number;
	totalSpheres: number;
}

const CounterWoundPoints: React.FC<CounterWoundPointsEl> = ({ solvedSpheres, totalSpheres }) => {

  return (
    <Container>
      <HeadLine>Activities Improved</HeadLine>
      <CounterContainer>
        <Counter>{solvedSpheres + "/" + totalSpheres}</Counter>
      </CounterContainer>
    </Container>
  );
};

export default CounterWoundPoints;
