import { formatDataToPosition } from "./index";
const dataJSON = require('../../data/graph_020.json');

type Sphere = {
  pointid: number,
  contentid: number,
  scale: number,
  wound: boolean,
  connectionids: number[],
  status: string,
}

type Point = {
  id: number,
  tx: number,
  ty: number,
  tz: number,
}

type Line = {
  [id:string]: number;
}

type Connection = {
  points: Array<number[]>,
  controlpoints: Line[],
  id: string,
  thickness: number,
  lineamount: number,
}

class GraphData {
  connections: Array<Connection>
  points: Point[]
  spheres: Sphere[]


  private static _instance:GraphData = new GraphData();

  constructor() {
    if(GraphData._instance){
        throw new Error("Error: Instantiation failed: Use GraphData.getInstance() instead of new.");
    }

    GraphData._instance = this;
    this.connections = dataJSON.connections;
    this.points = dataJSON.points;
    this.spheres = dataJSON.spheres;
  }

  /**
   *
   * Returns the instance on a GraphData object
   * @returns GraphData
   */
  public static getInstance():GraphData{
    return GraphData._instance;
  }

  /**
   *
   * Get the list of lines as Points
   * @returns Array<Array> Points
   */
  getLines() {
    return this.connections.map(connection => {
      return connection.controlpoints.map(point => {
        const pointElem = this.points.find(element => element.id === point.id)
        return pointElem;
      });
    })
  }

  getConnections () {
    return this.connections.map(connection => {
      let points = connection.controlpoints.map(point => {
        const pointElem = this.points.find(element => element.id === point.id)
        return formatDataToPosition(pointElem)
      });
      connection.points = points;
      return connection;
    })
  }
  

  /** *
   * Get the list of lines formatted as Array * @returns Array<Array>[posx, posy, posz]
   */
  getLinesFormatToArray() {
    return this.connections.map(connection => {
      return connection.controlpoints.map(point => {
        const pointElem = this.points.find(element => element.id === point.id)
        return formatDataToPosition(pointElem)
      });
    })
  }

  /**
   *
   * Get the list of Spheres with an scale property.
   * @returns Array of points
   */
  getRawSpheres() {
    return this.spheres.map(sphere => {
      const pointElem = this.points.find(element => element.id === sphere.pointid)
      return {...pointElem, "scale":sphere.scale }
    });
  }

/**
   *
   * Get the list of Spheres with the normalize data.
   * @returns Array of points
   */
  getNormalizedSpheres() {
    return this.spheres.map(sphere => {
      const pointElem = this.points.find(element => element.id === sphere.pointid)
      return {
        pointid: sphere.pointid,
        contentid: sphere.contentid,
        scale: sphere.scale,
        status: sphere.status,
        x: pointElem.tx,
        y: pointElem.ty,
        z: pointElem.tz,
        connectionids: sphere.connectionids,

      }
    });
  }
}

export const GraphModel = GraphData.getInstance()


