declare let XR8: any;

export default function customPipelineModule(customThree) {
  return {
    name: 'customPipeline',
    onStart: () => {
      const scene3 = customThree.xrScene();
      
      if(scene3){
        XR8.XrController.updateCameraProjectionMatrix({
          origin: scene3.camera.position,
          facing: scene3.camera.quaternion,
        });
      }
    }
  };
}
