import { unstable_batchedUpdates } from 'react-dom';
import { STATUS, useGraphStore } from '../../services/graphService';
import { useUIStore } from '../../services/uiService';
import { useXrStore } from '../../services/xrService';
import { getObjectPosition } from '../data';

const handleShowPopUp = (sphereId) => {
  useUIStore.getState().setShowLegendGraph(true);
  useGraphStore.getState().setSelectedSphere(sphereId, true);
};

const handleShowPopUpSolved = (sphereId) => {
  const lastSphereId = useGraphStore.getState().lastSphereSelected;
  if (lastSphereId !== sphereId) {
    useUIStore.getState().setShowLegendGraph(true);
    useGraphStore.getState().setSelectedSphere(sphereId, true);
  }
};

const handleNeutralLabel = (sphereId) => {
  useGraphStore.getState().setSelectedSphere(sphereId, true);
  setTimeout(
    () => useGraphStore.getState().setSelectedSphere(sphereId, false),
    3500,
  );
};

const SPHERE_TYPES = {
  [STATUS.healthySolved]: handleShowPopUpSolved,
  [STATUS.woundedSolved]: handleShowPopUpSolved,
  [STATUS.healthy]: handleShowPopUp,
  [STATUS.neutral]: handleNeutralLabel,
  [STATUS.wounded]: handleShowPopUp,
};

const handleSphereEvent = (sphere) => {
  const { sphereId } = sphere.object;
  unstable_batchedUpdates(() => SPHERE_TYPES[sphere.object.status]?.(sphereId));
};

const handleGroundPlaneEvent = (object) => {
  const pos = getObjectPosition(object.point);
  unstable_batchedUpdates(() => {
    useXrStore.getState().placeModelAR(pos);
  });
};

const EVENTS = {
  sphere: handleSphereEvent,
  groundPlane: handleGroundPlaneEvent,
};

const handleIntersectEvents = (object) => EVENTS[object.object.name]?.(object);

export const handle3DEvents = (intersects) =>
  intersects.find(handleIntersectEvents);
