import create from 'zustand';

type CurrentGameType = '' | 'anamorphic_ar' | 'graph_ar';

type GameType = {
  currentGame: CurrentGameType,
  isGameURLSet: boolean,
  gameComponent: object,
  isAnamorphicSolved: boolean,
  setCurrentGame: (game:string) => void,
  setIsGameURLSet: () => void,
  setGameComponent: (component) => void,
  isGraphGameActive: () => boolean,
  setIsAnamorphicSolved: () => void
}

export const useGameStore = create<GameType>((set, get) => ({
  currentGame: '',
  isGameURLSet: false,
  gameComponent: null,
  isAnamorphicSolved: false,

  setCurrentGame: (game:CurrentGameType) => {
    set({ currentGame: game });
  },

  setIsGameURLSet: () => {
    set({ isGameURLSet: true });
  },

  setGameComponent: (component) => {
    set({ gameComponent: component });
  },

  isGraphGameActive: () => {
    const { currentGame } = get();
    return currentGame === 'graph_ar'
  },

  setIsAnamorphicSolved: () => {
    set({ isAnamorphicSolved: true });  
  }
}));
