import { MobileMoveSVG } from '../../utils/svg'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LottieContainer = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Headline = styled.h3`
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
`

export const StyledSVG = styled(MobileMoveSVG)`
  width: 110px;
  height: 50px;
`
