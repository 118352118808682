import styled from 'styled-components';
import SAPLogo from '../Logo';
import Lottie from 'lottie-react';
import LoadingAnimationLottieFile from '../../lottie/loading-ar.json';

type MotionELement = {
  animate?: boolean,
  exit?: any
}

const ArLoadingLogo: React.FC<MotionELement> = ({animate = false}) => {
  return (
    <ArLoadingLogoContainer>
      <LogoItemContainer>
        <SAPLogo />
        {animate ? (
        <LoaderAnimatedLogoContainer>
          <Lottie
            autoplay
            loop
            animationData={LoadingAnimationLottieFile}
          />
        </LoaderAnimatedLogoContainer>) : null}
      </LogoItemContainer>
    </ArLoadingLogoContainer>
  );
}

const ArLoadingLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const LogoItemContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const LoaderAnimatedLogoContainer = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  height: 80px;
  transform: translate(-50%,-50%);
  width: 80px;
`

export default ArLoadingLogo;