import create from 'zustand';
import { GraphModel } from '../../utils/data';
import { SequentialModel } from '../../utils/data';
import { SequentialSphereType } from '../../utils/data/sequentialStatesData'

export type SphereStatusType = 'wounded' | 'healthy' | 'neutral' | 'healthySolved' | 'woundedSolved';

export type SphereType = {
  pointid: number;
  contentid: number;
  scale: number;
  isSelected: boolean;
  status: SphereStatusType;
  x: number;
  y: number;
  z: number;
  connectionids: number[];
};

export type ConnectionType = {
  controlpoints: object[],
  id: string,
  thickness: number,
  lineamount: number,
}

type GraphType = {
  spheres: SphereType[];
  connections: ConnectionType[];
  stepsSequentialGraph: SequentialSphereType[],
  currentStep: number,
  lastSphereSelected: number;
  setSelectedSphere: (id_sphere: number, isSelected: boolean) => void;
  setLastSphereSelected: (id_sphere: number) => void;
  setStatusSphere: (id_sphere: number, newStatus: SphereStatusType) => void;
  getSelectedSphere: () => SphereType;
  getSolvedSpheres: () => number;
  getTotalWoundedSpheres: () => number;
  getSphereById: (id: number) => SphereType;
  goToNextStep: () => SequentialSphereType;
  turnAllSpheresToNeutral: () => void;
  getDataActiveStep: () => SequentialSphereType;
  loadStepInToGraph: () => void;
  isWounded: (sphere: SphereType) => boolean;
  isHealthy: (sphere: SphereType) => boolean;
  isSolved: (sphere: SphereType) => boolean;
  setConnectionThicknessById: (connectionId: string, newThickness:number) => void;
};

export type StatusType = {
  readonly neutral: SphereStatusType,
  readonly healthy: SphereStatusType,
  readonly wounded: SphereStatusType,
  readonly healthySolved: SphereStatusType,
  readonly woundedSolved: SphereStatusType,
}

export const STATUS: StatusType = {
  neutral: "neutral",
  healthy: "healthy",
  wounded: "wounded" ,
  healthySolved: "healthySolved",
  woundedSolved: "woundedSolved",
}

/**
 * Service that controls the data of the Graph game
 */
export const useGraphStore = create<GraphType>((set, get) => ({
  spheres: GraphModel.getNormalizedSpheres() as SphereType[],
  connections: GraphModel.getConnections() as ConnectionType[],
  stepsSequentialGraph: SequentialModel.pdcSteps as SequentialSphereType[],
  currentStep: 0,
  lastSphereSelected: null,

  /**
   * Set the sphere to selected.
   * @param id_sphere Integer
   * @param newStatus boolean
   */
  setSelectedSphere: (id_sphere: number, isSelected: boolean): void => {
    set((state) => ({
      spheres: state.spheres.map((sphere) =>
        sphere.pointid === id_sphere ? { ...sphere, isSelected: isSelected } : sphere,
      ),
    }));
  },

   /**
   * Set the lastSphere Id for further validation.
   * @param id_sphere Integer

   */
    setLastSphereSelected: (id_sphere: number): void => {
      set({ lastSphereSelected: id_sphere });      
    },

  /**
   * Set the status of the sphere.
   * @param id_sphere Integer
   * @param newStatus SphereStatus
   */
  setStatusSphere: (id_sphere: number, newStatus: SphereStatusType): void => {
    set((state) => ({
      spheres: state.spheres.map((sphere) =>
        sphere.contentid === id_sphere ? { ...sphere, status: newStatus } : sphere,
      ),
    }));
  },

  /**
   *
   * Get the selected sphere.
   * @returns Sphere
   */
  getSelectedSphere: (): SphereType => {
    const { spheres } = get();
    return spheres.find((sphere) => sphere.isSelected);
  },

  /**
   * 
   * Get an Sphere given an Id
   * @param id 
   * @returns 
   */
  getSphereById: (id: number): SphereType => {
    const { spheres } = get();
    return spheres.find((sphere) => sphere.pointid === id)
  },

  /**
  *
  * Get the solved spheres.
  * @returns number of solved spheres
  */
  getSolvedSpheres: (): number => {
    const { spheres } = get();
    return spheres.reduce((acc: number, current: SphereType) => {
      if(current.status === STATUS.woundedSolved) { acc++ } return acc
    },0);
  },

   /**
   *
   * Get number of wounded spheres.
   * @returns total of wounded spheres
   */
  getTotalWoundedSpheres: (): number => {
    const { spheres } = get();
    return spheres.filter((sphere) => sphere.status !== STATUS.neutral).length;
  },

  /**
   * Move to the next Step in the game
   */
  goToNextStep: () : SequentialSphereType => {
    const newIndex = get().currentStep+1 ;
    set(state => ({
      currentStep: newIndex
    }));

    return get().stepsSequentialGraph[newIndex];
  },

  /**
   * Get the active step of the graph
   * @returns SequentialSphereStatusType 
   */
  getDataActiveStep: (): SequentialSphereType => {
    const { currentStep, stepsSequentialGraph } = get();
    return stepsSequentialGraph[currentStep];
  },

  /**
   * Change the status of all the spheres to neutral
   */
  turnAllSpheresToNeutral: (): void => {
    set((state) => ({
      spheres: state.spheres.map(sphere => ({ ...sphere, status: STATUS.neutral  })),
    }));
  },

  /**
   * Loads the new step on the Graph
   */
  loadStepInToGraph: (): void => {
    const { getDataActiveStep, setStatusSphere /*, turnAllSpheresToNeutral*/ } = get();
    // turnAllSpheresToNeutral();
    getDataActiveStep().sphereStatus.map(activeStepSphere =>
      setStatusSphere(activeStepSphere.id,activeStepSphere.newStatus)
    )
  },
    /**
   *
   * Check if the status of a sphere is wounded
   * @returns true if selectedSphere is wounded
   */

  isWounded: (sphere : SphereType): boolean  => {
    if(sphere?.status === STATUS.wounded || sphere?.status === STATUS.woundedSolved) return true
    return false
  },

    /**
   *
   * Check if the status of a sphere is healthy
   * @returns true if selectedSphere is healthy
   */
  isHealthy: (sphere : SphereType): boolean  => {
    if(sphere?.status === STATUS.healthy || sphere?.status === STATUS.healthySolved) return true
    return false
  },

     /**
   *
   * Check if the status of a sphere is solved
   * @returns true if selectedSphere is solved
   */
  isSolved: (sphere : SphereType): boolean  => {
    if(sphere?.status === STATUS.healthySolved || sphere?.status === STATUS.woundedSolved) return true
    return false
  },


  /**
   * Set the new thickness of a connection
   */
  setConnectionThicknessById: (connectionId: string, newThickness: number): void => {
    // Converts the thickness in cm (received from 3d artists) to the thickness in our absolute values from the graph json file
    // the divisor (128.) was calculated by dividing the cm value of one connection (3cm) by the thickness of that connection in the graph file (0.023409012705087662)
    const convertCmThicknessToArValue = (fileThickness: number): number => {
      return fileThickness / 128.155767943;
    };

    set((state) => ({
      connections: state.connections.map(connection =>
        connection.id === connectionId ? { ...connection, thickness: convertCmThicknessToArValue(newThickness) } : connection,
      ),
    }));
  }

}));
