import React, { useRef, useEffect } from 'react';
import { LegendContainer } from './styles';
import gsap from 'gsap';
import { useUIStore } from '../../services/uiService';

interface PopUpContainerEl {
  children: any;
  isDelayed?: boolean;
}

const PopUpContainer: React.FC<PopUpContainerEl> = ({
  children,
  isDelayed = false,
}) => {
  const { popupAnimationDelay } = useUIStore();
  const delayToSeconds = popupAnimationDelay / 1000 + 1; // + 1s more to await until the first FocusArea animation is finished
  const containerRef = useRef(null);
  const childrenRef = useRef(null);
  const delayPopup = isDelayed ? delayToSeconds : 0;
  const timeline = gsap.timeline();


  useEffect(() => {
    timeline
      .fromTo(
        containerRef.current,
        { y: 80, opacity: 0, duration: 1 },
        {
          duration: 0.5,
          y: 0,
          opacity: 1,
          delay: delayPopup,
          ease: 'expo.inout',
        },
      )
      .from(childrenRef.current, {
        opacity: 0,
        ease: 'power3.inOut',
        duration: 1,
      });
  }, []);

  
  return (
    <LegendContainer ref={containerRef}>
      <div ref={childrenRef}>{children}</div>
    </LegendContainer>
  );
};

export default PopUpContainer;
