export default new Float32Array([
    /*
     0.445947468280792, 0.461086004972458, 0.499557614326477, // L00, irradiance, pre-scaled base
     0.512040853500366, 0.597576379776001, 0.698624789714813, // L1-1, irradiance, pre-scaled base
     0.093078918755054, 0.113208696246147, 0.130547091364861, // L10, irradiance, pre-scaled base
     0.043409802019596, 0.042465642094612, 0.041656911373138, // L11, irradiance, pre-scaled base
     0.024007732048631, 0.032274402678013, 0.039529222995043, // L2-2, irradiance, pre-scaled base
     0.050532564520836, 0.075997874140739, 0.095304980874062, // L2-1, irradiance, pre-scaled base
     -0.021421678364277, -0.034971170127392, -0.046167679131031, // L20, irradiance, pre-scaled base
     -0.001328815240413, -0.000764847092796, -0.000307874492137, // L21, irradiance, pre-scaled base
     -0.168964937329292, -0.210869938135147, -0.253009885549545, // L22, irradiance, pre-scaled base
*/

/*
0.517345547676086,  0.519174337387085,  0.565939605236053, // L00, irradiance, pre-scaled base
0.593696177005768,  0.612012743949890,  0.725487768650055, // L1-1, irradiance, pre-scaled base
0.064949236810207, -0.064727000892162, -0.067595176398754, // L10, irradiance, pre-scaled base
0.025325916707516,  0.025432750582695,  0.030676694586873, // L11, irradiance, pre-scaled base
0.044622465968132,  0.045917786657810,  0.057318259030581, // L2-2, irradiance, pre-scaled base
0.024541916325688, -0.025688873603940, -0.029981937259436, // L2-1, irradiance, pre-scaled base
0.066582515835762, -0.068507604300976, -0.080708824098110, // L20, irradiance, pre-scaled base
0.000870726304129,  0.001265337690711,  0.001791289192624, // L21, irradiance, pre-scaled base
0.255654096603394, -0.261891335248947, -0.302828460931778, // L22, irradiance, pre-scaled base
*/

/*
//Provence Studio
 0.679721772670746,  0.673761725425720,  0.697039008140564, // L00, irradiance, pre-scaled base
 0.345209151506424,  0.336941629648209,  0.347897469997406, // L1-1, irradiance, pre-scaled base
 0.036312043666840,  0.069892279803753,  0.137357220053673, // L10, irradiance, pre-scaled base
-0.112106345593929, -0.115151688456535, -0.111257195472717, // L11, irradiance, pre-scaled base
 0.001661477494054,  0.005611319094896,  0.009421780705452, // L2-2, irradiance, pre-scaled base
-0.022899957373738, -0.015230889432132, -0.003885938320309, // L2-1, irradiance, pre-scaled base
-0.009742114692926, -0.008372512646019, -0.000982324592769, // L20, irradiance, pre-scaled base
-0.030149269849062, -0.045085974037647, -0.082215875387192, // L21, irradiance, pre-scaled base
-0.083535574376583, -0.076689794659615, -0.073313049972057, // L22, irradiance, pre-scaled base
*/
 
/*
//St Peters Square
 0.976994812488556,  0.951759219169617,  0.951796591281891, // L00, irradiance, pre-scaled base
 0.455498337745667,  0.444178253412247,  0.498306602239609, // L1-1, irradiance, pre-scaled base
 0.572874069213867,  0.510012567043304,  0.490221112966537, // L10, irradiance, pre-scaled base
-0.380089163780212, -0.335631787776947, -0.313538581132889, // L11, irradiance, pre-scaled base
-0.304037958383560, -0.273223966360092, -0.269708096981049, // L2-2, irradiance, pre-scaled base
 0.468591541051865,  0.422394633293152,  0.418785005807877, // L2-1, irradiance, pre-scaled base
 0.082430601119995,  0.084128335118294,  0.084957607090473, // L20, irradiance, pre-scaled base
-0.646792709827423, -0.621119379997253, -0.666407942771912, // L21, irradiance, pre-scaled base
 0.116637043654919,  0.123483031988144,  0.134633108973503, // L22, irradiance, pre-scaled base
*/

//Studio
 1.361521244049072,  1.592738509178162,  1.941882729530334, // L00, irradiance, pre-scaled base
 0.834527790546417,  0.973758518695831,  1.182983994483948, // L1-1, irradiance, pre-scaled base
 0.531079351902008,  0.621887564659119,  0.759838819503784, // L10, irradiance, pre-scaled base
-1.038407444953918, -1.214803934097290, -1.483230590820313, // L11, irradiance, pre-scaled base
-0.143312796950340, -0.167427211999893, -0.203745722770691, // L2-2, irradiance, pre-scaled base
 0.072688169777393,  0.084983594715595,  0.103501550853252, // L2-1, irradiance, pre-scaled base
-0.017314992845058, -0.020217088982463, -0.024551915004849, // L20, irradiance, pre-scaled base
-0.087908431887627, -0.102982610464096, -0.126021698117256, // L21, irradiance, pre-scaled base
 0.012701759114861,  0.015006247907877,  0.018845051527023, // L22, irradiance, pre-scaled base


],
);