import styled from 'styled-components'
import { BoldText } from '../../globalStyles'

export const FocusAreaContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
	pointer-events: none;
`;

export const FocusAreaIcon = styled.div`
  position: relative;
	width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* for adjusting the focus lens to screen position (0,0) */
  padding-bottom: 80px;
`;

export const FocusAreaText = styled(BoldText)`
position: absolute;
width: fit-content;
bottom: 60px;
font-size: 13px;
text-transform: uppercase;
`

export const LottieContainer = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
`