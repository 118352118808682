import { useEffect, useRef } from 'react'


/**
 * returns a sleep function with auto clean up
 * @returns {(ms:number) => Promise}
 */
export default function useSleep() {
    const cleanUpTimeoutRefs = useRef([])

    const sleep = (milliseconds) => {
        if (milliseconds === 0) {
            return Promise.resolve()
        }
        return new Promise((resolve) => {
            const timeout = setTimeout(resolve, milliseconds )
            cleanUpTimeoutRefs.current.push(timeout)
        })
    }

    useEffect(() => {
        const currentCleanUpRefs = cleanUpTimeoutRefs.current;

        return () => {
            for (let index = 0; index < currentCleanUpRefs.length; index++) {
                const timeoutRef = currentCleanUpRefs[index]
                clearTimeout(timeoutRef)
            }
        }
    }, [])

    return sleep
}
