import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSleep from '../../hooks/useSleep';
import {
  CircularBarTime,
  BarContainer,
  TextWrapper,
  Container,
  CircularBarText,
  BarBackground,
} from './styles';
import gsap from 'gsap';

const viewBox = 600;
const outerCircleRadius = 50; // %
const mainCircleRadius = outerCircleRadius - 2; // %
const innerCircleRadius = mainCircleRadius - 2; // %
const innerCircumference = 2 * Math.PI * (viewBox * (innerCircleRadius / 100));
const circumference = 2 * Math.PI * (viewBox * (mainCircleRadius / 100));
const outerCircumference = 2 * Math.PI * (viewBox * (outerCircleRadius / 100));
const initialAnimationDelay = 1000;

const CircularBar = ({ text, value, days, hours }) => {
  const [animationInited, setAnimationInited] = useState(false);
  const containerRef = useRef(null);
  const sleep = useSleep();
  const color = 'rgba(191, 255, 213, 1)';

  const progressStrokeDashoffset = useMemo(() => {
    if (!animationInited) {
      return circumference;
    }
    return circumference - (value / 100) * circumference;
  }, [value, animationInited]);

  useEffect(() => {
    sleep(initialAnimationDelay).then(() => setAnimationInited(true));
  }, [sleep]);

  useEffect(() => {
    gsap.fromTo(
      containerRef.current,
      { opacity: 0 },
      {
        duration: 1,
        opacity: 1,
        ease: 'expo.inout',
      },
    );
  }, []);


  return (
    <Container>
      <BarContainer ref={containerRef}>
        <svg
          width="100%"
          height="100%"
          viewBox={`0 0 ${viewBox} ${viewBox}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.4"
            cx="50%"
            cy="50%"
            r={innerCircleRadius + '%'}
            strokeDasharray="1 4"
            strokeWidth="14"
            stroke="url(#paint0_linear_1820_7027)"
            mask="url(#innerCircleMask)"
          />
          <circle
            className="outerCircle"
            cx="50%"
            cy="50%"
            r={outerCircleRadius + '%'}
            stroke="white"
            strokeWidth="1"
            strokeOpacity="0.6"
            opacity="0.5"
            strokeLinecap="round"
            strokeDasharray={outerCircumference}
            strokeDashoffset={outerCircumference}
            filter="drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))"
          />
          <circle
            cx="50%"
            cy="50%"
            r={mainCircleRadius + '%'}
            filter={`drop-shadow(1px 1px 5px ${color})`}
            strokeDasharray={circumference + ' ' + circumference}
            strokeDashoffset={progressStrokeDashoffset}
            stroke={color}
            strokeWidth="8"
            strokeLinecap="round"
          />
          <defs>
            <mask id="innerCircleMask">
              <circle
                className="innerCircle"
                cx="50%"
                cy="50%"
                r={innerCircleRadius + '%'}
                strokeDashoffset={innerCircumference}
                strokeDasharray={innerCircumference}
                strokeWidth="9%"
                stroke="white"
                // fill="white"
              />
            </mask>
            <linearGradient
              id="paint0_linear_1820_7027"
              x1="94.0691"
              y1="107.277"
              x2="466.586"
              y2="506.529"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.23913" stopColor="#79BAAD" />
              <stop offset="1" stopColor="#83D7AE" />
            </linearGradient>
          </defs>
        </svg>
        <TextWrapper>
          <CircularBarText>{text}</CircularBarText>
          <CircularBarTime>{days + (days === 1 ? ` day` : ` days`)}</CircularBarTime>
          <CircularBarTime>{hours + (hours === 1 ? ` hour` : ` hours`)}</CircularBarTime>
        </TextWrapper>
        <BarBackground />
      </BarContainer>
    </Container>
  );
};

export default React.memo(CircularBar);
