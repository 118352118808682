import styled from 'styled-components';
import { SAPLogoSVG } from '../../utils/svg';

const StyledLogo = styled(SAPLogoSVG)`
  position: fixed;
  margin: 1.5rem;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;
const SAPLogo = () => {
  return <StyledLogo />;
};

export default SAPLogo;
