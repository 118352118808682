import ButtonArrow from '../components/buttons/ButtonArrow';
import { FooterContainer, FooterLink, TitleTxt, TitleTxtContainer, ViewContainer, ButtonContainer, } from './styles'
import SAPLogo from '../components/Logo';
import { useHistory } from 'react-router-dom';
import { Paragraph } from '../globalStyles'
import { ContentModel } from '../../src/utils/data';

function PermissionPage() {
  const history = useHistory();
  return (
    <>
      <SAPLogo />
      <ViewContainer>
        <TitleTxtContainer>
          <TitleTxt>{ContentModel.permissionsPage.headline}</TitleTxt>
          <Paragraph>{ContentModel.permissionsPage.intro_txt}</Paragraph>
        </TitleTxtContainer>
        <ButtonContainer>
          <ButtonArrow
            width="280"
            isWhite
            text={ContentModel.permissionsPage.instructions}
            onClick={() => history.push('/graph_ar')}
          />
        </ButtonContainer>
        <FooterContainer>
          <FooterLink to="/anamorphic_ar">AR 1: Anamorphic</FooterLink>
          <FooterLink to="/graph_ar">AR 2: Graph</FooterLink>
        </FooterContainer>
      </ViewContainer>
    </>
  );
}

export default PermissionPage;
