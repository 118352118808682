import React from 'react';
import styled, { css } from 'styled-components';

const ZoomButton = (props) => {
    return(
        <ZoomBtnContainer>
            <ZoomBtn onClick={props.zoomIn}>+</ZoomBtn>
            <ZoomBtn onClick={props.zoomOut}>-</ZoomBtn>
        </ZoomBtnContainer>
    )
}

const ZoomBtn = styled.button`
    display: flex;
    align-items: center;
    border-radius: 50px;
    color: blue;
    border: 2px solid blue;
    color: blue;
    font-family:'56 Bold';
    font-size: 20px;
    width: 30px;
    height: 50px;
    padding: 0 25px;
`;
const ZoomBtnContainer = styled.div`
    display: flex;
    position: absolute; 
    justify-content: center;
    flex-direction: column;
    left: 80%; 
    top: 50%;
`


export default ZoomButton;