import { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import customPipelineModule from './customPipelineModule';
import ARScene from '../ArScene';
import customThreejsPipelineModule from './customThreejsPipelineModule';
import { LoadingFactory } from './loading-module/loading-module';
import LoadingScreen from './loading-module/loadingScreen';
import TouchContainer from '../TouchContainer';
import UIGraphModel from '../../UIGraphModel';
import { useXrStore } from '../../../services/xrService';
import UIAnamorphicModel from '../../UIAnamorphicModel';

declare let XR8: any;
declare let XRExtras: any;

(window as any).THREE = THREE;

export default function XRCanvas(props) {
  const mainCanvas = useRef();
  const customThree = customThreejsPipelineModule();
  const Loading = LoadingFactory();
  const game = props.route.params.game;

  const onxrloaded = () => {
    XR8.addCameraPipelineModules([
      // Existing pipeline modules.
      XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
      Loading.pipelineModule(), // Detects unsupported browsers and gives hints.
      XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
      XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
      customThree, // Rendering Pipeline Module
      customPipelineModule(customThree), // Custom pipeline modules.
    ]);
    
    XR8.XrController.configure({
      enableLighting: false,
      enableWorldPoints: true,
      disableWorldTracking: false, // to be checked if this improves performance
    });
    
    XR8.run({ canvas: mainCanvas.current });

  };
  
  useEffect(() => {
    // XRExtras.Loading.showLoading({ onxrloaded }); // Show loading screen ?
    Loading.showLoading({ onxrloaded });

    return () => {
      XR8.stop();
      XR8.clearCameraPipelineModules();
    };
  });

  return (
    <TouchContainer>
      { game === "anamorphic_ar" ? <UIAnamorphicModel /> : <UIGraphModel /> }
      <Canvas
        ref={mainCanvas}
        onContextMenu={(e) => {
          e.nativeEvent.preventDefault();
        }}
        style={{ width: '100%', height: '100%' }}
        gl={{ antialias: true, alpha: false }}
        onCreated={({ gl }) => {
          useXrStore.getState().setRenderer(gl);
        }}
      >
        <ARScene route={props.route.params.game} />
      </Canvas>
      <LoadingScreen />
    </TouchContainer>
  );
}
