import React, { useEffect, useMemo } from 'react';
import { TouchArea } from './styles';
import { useXrStore } from '../../../services/xrService';
import { useGraphStore } from '../../../services/graphService';
import { Raycaster, Vector2 } from 'three';
import { handle3DEvents } from '../../../utils/events/events';
import { GRAPH_SCREENS, useUIStore } from '../../../services/uiService';

export default function TouchContainer({ children }) {
  const { scene3, isSceneInitialised, screenTouchedFirstTimeAR, groundRef } = useXrStore();
  const { setScreenGraphGame, showImprovementCircularBar } = useUIStore();
  const { getSelectedSphere } = useGraphStore()

  let scene = null;
  let requestAnim; // Where requestFrameAnimation will be stored

  if (isSceneInitialised) {
    scene = scene3.scene.scene;
  }

  const { raycaster } = useMemo(() => {
    if (!scene) return {};

    return {
      raycaster: new Raycaster(),
    };
  }, [scene, 
  ]);


  useEffect(() => {
    if (screenTouchedFirstTimeAR) {
      // When user places graph (screenTouched === true), stop graph placement logic && set new game
      setScreenGraphGame(GRAPH_SCREENS.transition);
      cancelAnimationFrame(requestAnim);
    }
  }, [screenTouchedFirstTimeAR]);

  // Starts raycaster process and handles intersections (from VIEWPORT_CENTER)
  const triggerRaycaster = (intersectedObj) => {
    const VIEWPORT_CENTER: Vector2 = new Vector2(0, 0);
    raycaster.setFromCamera(VIEWPORT_CENTER, scene3.scene.camera);
    const intersects = raycaster.intersectObject(intersectedObj, true);
    handle3DEvents(intersects);
  };

  // Every x time, raycaster "searches" for a sphere inside the focus area.
  useEffect(() => {
    const interval = setInterval(() => {
      // Raycast actions only if user has tapped and UI not being shown at that moment.
      if (screenTouchedFirstTimeAR && !getSelectedSphere() && !showImprovementCircularBar) {
        // Spheres in intersection are detected and validated
        triggerRaycaster(scene3.scene.scene);
      }
    }, 700);

    return () => clearInterval(interval);
  }, [screenTouchedFirstTimeAR, getSelectedSphere, raycaster, scene3]);

  // for model positioning 
  const updateAnimFrame = () => {
    if (!groundRef.current || screenTouchedFirstTimeAR) return;
    triggerRaycaster(groundRef.current);
    requestAnim(updateAnimFrame);
  };

  const startAnimationFrameModelPreview = () => {
    if (isSceneInitialised) {
      requestAnim = requestAnimationFrame;
      requestAnim(updateAnimFrame);
    }
  };

  useEffect(() => {
    startAnimationFrameModelPreview();
  }, [scene]);

  return (
    <TouchArea>
      {children}
    </TouchArea>
  );
}
